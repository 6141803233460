// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cgv-js": () => import("./../../../src/pages/cgv.js" /* webpackChunkName: "component---src-pages-cgv-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infos-pratiques-js": () => import("./../../../src/pages/infos-pratiques.js" /* webpackChunkName: "component---src-pages-infos-pratiques-js" */),
  "component---src-pages-prestations-js": () => import("./../../../src/pages/prestations.js" /* webpackChunkName: "component---src-pages-prestations-js" */),
  "component---src-pages-tarifs-js": () => import("./../../../src/pages/tarifs.js" /* webpackChunkName: "component---src-pages-tarifs-js" */),
  "component---src-templates-prestation-js": () => import("./../../../src/templates/prestation.js" /* webpackChunkName: "component---src-templates-prestation-js" */)
}

